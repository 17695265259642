import React from 'react';

import './DocumentsReference'

class DocumentsReference extends React.Component {
  render() {
    return (
      <div className="DocumentsReference">
        <h5>Documents Reference</h5>
        <p>Coming Soon</p>
      </div>
    )
  }
}

export default DocumentsReference;

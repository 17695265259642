import React from 'react';

import './DocumentsOverview'

class DocumentsOverview extends React.Component {
  render() {
    return (
      <div className="DocumentsOverview">
        <h5>Documents Overview</h5>
        <p>Coming Soon</p>
      </div>
    )
  }
}

export default DocumentsOverview;
